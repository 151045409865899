<template>
  <gf-content overflow>
    <div>
      <gf-table v-loading="loading" :data="histories" :scrollable="false">
        <el-table-column label="Date" prop="date" width="180">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Time" prop="time" width="180">
          <template slot-scope="slot">
            {{ $GetTime(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Subject" prop="subject">
        </el-table-column>
        <el-table-column label="Description" prop="description">
          <template slot-scope="slot">
            <div v-for="(value, name) in slot.row.description" :key="name">
              <span class="font-weight-bold">{{ name }}: </span>
              <span>{{ value }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="User" prop="user">
          <template slot-scope="slot">
            {{ slot.row.user.fname }} {{ slot.row.user.lname }}
          </template>
        </el-table-column>
      </gf-table>
    </div>
  </gf-content>
</template>

<script>
import StockAdjustmentService from '@/services/v1/StockAdjustment'
import StockAdjustmentHistoryService from '@/services/v1/StockAdjustmentHistory'

export default {
  data() {
    return {
      stockAdjustmentId: this.$route.params.id,
      saNumber: null,

      histories: [],
      loading: false
    }
  },
  methods: {
    async getStockAdjustment() {
      try {
        const saService = new StockAdjustmentService(this.stockAdjustmentId)
        const result = await saService.get()
        this.saNumber = result.data.sa_number
      } catch (error) {
        this.$Error(error)
      } finally {
      }
    },
    async getSAHistories() {
      try {
        this.loading = true
        const shService = new StockAdjustmentHistoryService(this.stockAdjustmentId)
        const result = await shService.list()
        this.histories = result.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    this.$emit('update:active', 2)

    await this.getStockAdjustment()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock control' },
      { title: 'Stock Adjustments' },
      { title: this.saNumber },
      { title: 'History' }
    ])

    this.getSAHistories()
  }
}
</script>
